.msgbar{
    text-align: center;
    margin-bottom: 20px;
    color: red;
    font-size: 12px;

}

.form-group {
    background: #ffffff;
    border-radius: 34px;
    margin-top: 2px;
    padding-top: 23px;
    padding-bottom: 23px;
    padding-right: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }

.fgn{
    background-color: rgb(255, 240, 240);
    height: 50px;
    width: 12em; 
    border-radius: 5px ;
    margin-top: 10px;
    margin-bottom: 50px; 
}

.ggn{
    background-color: white;
    margin-left: 10px;  
}

.fi_iwori{
    background-color: rgb(237, 250, 225);
}
 