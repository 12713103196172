.se_tin22{
    width: 180px;
    margin-right: 5px;
    margin-left: 5px;
    border-style: solid;
    background-color: transparent !important;
}


.se_inp2 {
    width: 70px;
  }

  .se_tin {
    width: 150px;
    margin-right: 15px;
    margin-left: 15px;
    border-style: solid;
    background-color: transparent !important;
  }
  
  .se_divcc {
    background: #ecffe5;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    justify-content: center;
    margin-top: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 0px;
    padding-right: 0;
    padding-left: 0;
  }
  
  .se_div1 {
    margin-bottom: 10px;
  }
  
  .se_div2 {
    margin-bottom: 10px;
  }
  
  .se_divflex {
    display: flex;
    /*text-align: center;*/
    /*justify-content: center;*/
  }
  
  .se_inp {
    width: 100px;
  }
  
  .se_divgroup {
  }
  
  se_btnyy {
  }
  
 
  
  @media (min-width: 300px) {
    .se_brrr {
      left: 8px;
    }
  }
  
  @media (min-width: 576px) {
    .se_brrr {
      left: 80px;
    }
  }
  
  @media (min-width: 768px) {
    .se_brrr {
      left: 170px;
    }
  }
  
  @media (min-width: 992px) {
    .se_brrr {
      left: 280px;
    }
  }
  
  @media (min-width: 1200px) {
    .se_brrr {
      left: 360px;
    }
  }
  
  .se_brrr {
    /*background-color: red;*/
    width: 84px;
    position: relative;
    /*left: 32%;*/
    /*margin-left: 50%;*/
  }
  
  se_btt2 {
    /*font-size: 22px;*/
  }
  
  .se_btt2 {
    margin-top: 15px;
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .se_inum {
    margin-top: 6px;
    margin-right: 13px;
  }
  
  .eu_eue{
      margin-top: 5px;
      margin-bottom: 10px;
  }
  .fdfd{
    background-color: red;
  }