.tr_iodfw8 {
}

.tr_dinvw {
  height: 26px;
  background: rgb(125, 207, 144);
  margin-right: 0px;
  margin-left: 0;
}

.tr_tbjsl{
  font-size: 13px;
  font-family: Andada, serif;
}

.ux_fvjwe{
  background-color: rgb(46, 9, 53);
}

.ux_fvieow{
  background-color:  rgb(75, 3, 90) !important;
  color:  rgb(240, 222, 248) !important;
} 

.ux_fvtabowe{
  color:rgb(75, 3, 90) ;
}

.ux_fvtraod{
  background-color:  rgb(255, 255, 255) !important; ;
}

.ux_fvtabin{
  background-color:rgb(240, 231, 231) !important;  ;
}

.ux_tooltoe3{
  font-size: 18px;
  margin-left: 4px;  
  
}

.ux_alidk33{
  margin-top: -7px;
  color:rgb(75, 3, 90);
}

