.dist_top_container{
    display: flex;
    justify-content: space-between;
}

.dist_main_container{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    border: 1px solid lightgrey;
    padding: 20px;
}




.dist_each_cell{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px; 

}

.dist_text{
    font-weight: bolder;
    margin-bottom: 4px;
    text-decoration: underline;
}

.dist_middle_container{
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
}

.dist_one{
    display: flex;
}

.dist_two{
    /* display: flex; */
    position: relative;
    top: 3px;
}

.dist_btn{
    margin-right: 14px;
    padding: 3px 10px;
    cursor: pointer;
}
.dist_btn2{ 
    padding: 3px 10px;
    cursor: pointer;
}
.dis_tab{
    padding: 10px 20px;
    border: 1px solid lightgrey;
    border-top-right-radius: 25px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px; 
    cursor: pointer;
}

.dis_select{
    padding: 10px 20px;
    border: 1px solid lightgrey;
    background-color: black;
    color: white;
    border-top-right-radius: 25px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px; 
    cursor: pointer;
}
.dis_tab:hover{
    background-color: rgb(240, 240, 240);
}

@media only screen and (max-width: 1100px) {
 
    .dist_main_container{
        width: 80%; 
}
}

@media only screen and (max-width: 770px) {
 
    .dist_main_container{
        width: 90%;   
}

 
}
@media only screen and (max-width: 390px) {
 
    .dist_main_container{
        width: 90%;   
}

.dist_top_container{
    display: block;  
}

.dist_middle_container{
    display: block; 
}

.dist_two{
    margin-top: 20px;
}

.dist_each_cell{
    margin-top: 10px; 
}
 
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px; 
    margin-top: 20px;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    font-size: 13px; 
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }

  .distro_td_last_child{
      font-weight: bolder;
  }