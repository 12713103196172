.sv_dp{
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center; 
}

.sv_in{
    justify-content: center;
    text-align: center;
    font-size: 1.15rem;
    margin: 15px;
}

.sv_as{
    font-size: 1.6rem;
    color: rgb(43, 0, 0); 
    margin-right: 0.7rem;
}

.sv_nda{
    font-size: 1.5rem;
    margin-top: 15px; 
    margin-left: 40px;
}

.sv_ttbb{
    font-size: 13px;
}

.sv_wioeud{
    margin-bottom: 10px;
}

.sv_isiod{
    margin-top: -5px;
}

.sv_scrol{
overflow: auto;
}

/* New Css */

.sales-viewer-header{
    font-size: 15px;
    font-weight: 700;
    border:none;
    background-color: transparent;
    font-family: Montserrat, sans-serif; 
}

.sales-viewer-header:focus{
    outline: none;
   
}

.sales_viewwer_datepicker{
    padding-left: 24px;
}

.sales_viewwer_dt{
    padding: 5px 10px;
    font-family: Montserrat, sans-serif; 
    border: 1px solid rgb(219, 219, 219);
}

.sales_delete_cta{
    margin-top: 40px;
    display: flex;
align-items: center;
grid-gap: 30px;
justify-content: center;
}

.salev_icon_wrapper{
    font-size: 16px;
    border: 1px solid rgb(202, 202, 202);
    padding: 5px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
}

.salev_icon_wrapper:hover{
    border: none;
    background:rgb(144, 204, 219) ;
    color: white;
}

.sales_edit_form{
    margin-top: 20px;
    background: #fff9f9 ;
    padding-bottom: 20px;
}

.card_sv{
 
}