.date_container{
 margin-top: 30px;
 font-weight: 500;
 font-size: 14px;
}

.wr_date_options{
    margin-left: 20px;

}

.wr_date_options select{
    padding: 5px 10px;
    
}

.weekly_loading{
    margin-top: 100px;
    text-align: center;
    margin-bottom: 150px;
}

.weekly_submitted_div{

    margin-top: 100px;
    text-align: center;
    margin-bottom: 200px;
}

.weekly_span_edit{
    cursor: pointer;
    text-decoration: underline;
    
}

.weekly_saved{
    font-size: 60px;
    margin-bottom: 50px;
    fill: rgb(52, 149, 179);
}