.Button_Div{
    display: flex;
  align-self: flex-end;
  flex: 20%;
  padding-left: 20px;
  position: relative;
  top: -20px;
   
}

.qa_question{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 20px;
    margin-bottom: 10px; 
    flex: 80%;
}
.weeklyr_majordv{
    display: flex; 
    justify-content: space-between;
    width: 100%; 
}

.weeklyr_question2{
    margin-bottom: 10px;
}

.weekly_optional{
    font-size: 13px;
}