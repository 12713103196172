.is_text{
    width: 80%; 
    margin-left: auto;
    margin-right: auto;
}

.is_cent{
    text-align: center;
}

.is_ubiwube{
    background-color: rgb(231, 253, 253);
}

.input_trans{
    background-color:transparent !important
}

.fgn2{
    margin-bottom: 30px;
}

.text_area_stock{
    width: 100%;
    border: 1px solid lightgrey;
    padding: 10px;
}