.se_tin {
     width: 40% !important ;
    margin-right: 15px;
    margin-left: 15px;
    border-style: solid;
    background-color: transparent !important;
  }
  .se_edit_b{
    border: 1px solid rgb(196, 196, 196);
    padding: 10px 0;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .se_divcc {
    background: #f5fff2;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    justify-content: center;
    margin-top: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 0px;
    padding-right: 0;
    padding-left: 0;
  }
  
  .se_div1 {
    margin-bottom: 10px;
  }
  
  .se_div2 {
    margin-bottom: 10px;
  }

  .se_invoice_div{ 
    display: flex;
    justify-content: space-between;
    width: 310px;
  }
  
  .se_divflex {
    /* background-color: red; */
    display: flex;
    text-align: center;
   justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .se_inp {
    width: 150px;
  }
  
  .se_fjie{
    margin-bottom: 15px;
    color: red;
  }
  
 
  .se_btnyy {
    background: rgba(0,98,204,0);
    color: #000000;
    font-size: 16px;
  }
  
  /* @media (min-width: 300px) {
    .se_brrr {
      left: 5px;
    }
  }
  
  @media (min-width: 576px) {
    .se_brrr {
      left: 70px;
    }
  }
  
  @media (min-width: 768px) {
    .se_brrr {
      left: 160px;
    }
  }
  
  @media (min-width: 992px) {
    .se_brrr {
      left: 270px;
    }
  }
  
  @media (min-width: 1200px) {
    .se_brrr {
      left: 350px;
    }
  }
   */
  .se_brrr {
    /*background-color: red;*/
    width: 84px;
    position: relative;
    /*left: 32%;*/
  }
  
  se_btt2 {
    /*font-size: 22px;*/
  }
  
  .se_btt2 {
    margin-top: 15px;
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .se_inum {
    margin-top: 6px;
    margin-right: 13px;
  }
  
  .se_txtii {
      background-color: transparent !important;
      
  }

  .se_jiter{
    background-color: red;
  }

  .se_djj33{
    margin-bottom: 20px !important;
    margin-right: 25px;
  }
  .se_dk22{
    margin-left: 30px;
    margin-top: 6px;
  }


  /* New Css */

  .sales-form-edit{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    font-size: 15px;
    grid-gap: 20px; 
}