.pv_flex{
    display: flex;
   
}

.pv_ser{
    font-size: 2rem;
}

.pv_txt{
    font-family: Montserrat, sans-serif;
    font-size: 13px;
}

.btn_pv:hover{
    background-color: white!important;
}

.btn_pv:focus{
    background-color: white!important;
}

.pt_wue8r9 {
    margin-top: 5px;
    font-size: 24px;
    margin-left: 20px;
    color: rgb(136, 177, 238);
    cursor:pointer;
}

.pt_jiwue{
    margin-left: 5px;
}

.pt_wue8r9a {
    margin-top: 5px;
    font-size: 28px;
    margin-left: 20px;
    color: rgb(43, 0, 0); 
}