.psd_container{
    width : 70%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid gray;

}

.psd_subcontainer{
    display:flex;
    padding:20px;
   
    border: 1px solid lightgrey; 
}

.psd_policytitle{
    flex: 80%;
   
}

.psd_downloadlink{
    flex: 20%;
    border: none;
}

.psd_mainheader{
    text-align: center;
}

.psd_btn_policy_documents{
    padding: 5px 10px;
    cursor: pointer;
}