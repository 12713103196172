.pe_jjhhru22{
    margin-top: 20px;
    text-align: center;
    justify-content: center;
}
.pe_kd823{
    margin-left: 20px;
    margin-right: 20px;
}

.pe_btn{
    border: 0;
    padding:5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    margin-left: 20px;
    margin-top: -2px;
    border-radius: 5px;
    background-color: rgba(146, 203, 250, 0.445);
    color: rgb(9, 71, 121); ;
}

.pe_btn:focus{
   outline: none;
}

.pe_btn:hover{ 
    background-color: rgb(9, 71, 121); 
    color:rgba(255, 255, 255);
 }
 
.pe_dki33{
    margin-top: 30px; 
}

.pe_iufg83{
    width: 70%;
    color: black  !important; 
}

.pe_di89{ 
    position: relative;
    /* margin-left: 10% */
}

.pe_dfj993{
    font-size: 15px;
}

.staff-details-header{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto; 
    font-size: 14px;


}

.staff-details-bold  {
    color: #000;
    font-weight: bold;
    border: 1px solid rgb(236, 236, 236);
    padding: 5px;
}

.staff-details-cell {
    color: grey;
    border: 1px solid rgb(236, 236, 236);
    padding: 5px
}

.staff-details-cta--wrapper{
    text-align: center;
    margin-top: 20px;
}

 