.pcd_main{
    margin: 5px;
    background-color: rgb(218, 232, 245);
    padding: 15px;

}


.pcd_txt{
    font-family: Montserrat, sans-serif;
}

.pcd_btn{
    margin-top: 10px;
    text-align: center;
}

.pcd_btnn{
    margin-right: 4rem;
    margin-left: 4rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 10px;
}

.pcd_bbtn1:hover {
    background-color: blue !important; 
}