.vvv{
    margin-left: 20px;
}

.gig{
    display: flex;
    background-color:#f7f7f7;
}

#accesories-report2 {
    /* text-align: center; */
    font-size: 80%;
    font-family: Montserrat, sans-serif;   
  }

  .divent{
      /* background-color: green; */
  }

  .ggn input{
    width: 51px;
    margin-left: 21px;
  }

  .input_size{
    width: 51px; 
    background-color: aqua;
  }
  .tyyfc{
      width:88px
  }

  .fi_stcr{
      font-size: 0.9em;
      /* background-color: green; */
      margin-top: 10px;
  }
  .fi_stcr2{
      font-size: 0.8em;
  }

  .fi_scrn{
      font-size:0.9em;
      color: rgb(138, 74, 1);
  }
  .vw_pd{ 
      margin-top: 20px;
  }

  .vw_ud{
    text-decoration: underline;
  }
  .sju_9eijd{
      background-color: rgb(102, 17, 119) !important;
  }