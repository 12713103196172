.rs_skle9 {
    width: 30rem;
    text-align: center;
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }
  
  .rs_jfk1{
      margin-top: 10%;
  }
  .rs_iuru8 {
    width: 100%;
    text-align: center;
    justify-content: center;
    /* margin-top: 20px; */
  }
  
  .rs_jd8372 {
    margin-left: 20px;
    width: 152px;
    border: 1px solid rgb(177, 39, 39); 
    border-radius: 20px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
  }

  .rs_jd8372:hover {
      background-color: rgb(177, 39, 39);
      color: white;
      font-family: Montserrat, sans-serif;
  }
   
  .rs_93jdj{
      justify-content: center;  
  }

  .rs_jdje822{
    font-family: Montserrat, sans-serif;
  }

  .rs_00sdsdf{
      background-color:rgb(177, 39, 39) ;
      height: 40px;
      margin-top: 20px;
      color: rgb(221, 220, 220);
      font-family: Montserrat, sans-serif;
      padding-top: 11px;
      padding-left: 20px;
      font-size: 14px;
  }

  .rs_e3i4{
      padding-top: 20px; 
      border: 1px solid rgb(177, 39, 39) ;
      padding-left: 20px;
  }