.navigation-clean-button {
  /* background: #fff; */
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

@media (min-width:768px) {
  .navigation-clean-button {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.navigation-clean-button .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.navigation-clean-button .navbar-brand:hover {
  color: #222;
}

.navigation-clean-button.navbar-dark .navbar-brand:hover {
  color: #f0f0f0;
}

.navigation-clean-button .navbar-brand img {
  height: 100%;
  display: inline-block;
  margin-right: 10px;
  width: auto;
}

.navigation-clean-button .navbar-toggler {
  border-color: #ddd;
}

.navigation-clean-button .navbar-toggler:hover, .navigation-clean-button .navbar-toggler:focus {
  background: none;
}

.navigation-clean-button.navbar-dark .navbar-toggler {
  border-color: #555;
}

.navigation-clean-button .navbar-toggler {
  color: #888;
}

.navigation-clean-button.navbar-dark .navbar-toggler {
  color: #eee;
}

.navigation-clean-button .navbar-collapse, .navigation-clean-button .form-inline {
  border-top-color: #ddd;
}

.navigation-clean-button.navbar-dark .navbar-collapse, .navigation-clean-button.navbar-dark .form-inline {
  border-top-color: #333;
}

.navigation-clean-button .navbar-nav a.active, .navigation-clean-button .navbar-nav > .show > a {
  background: none;
  box-shadow: none;
}

.navigation-clean-button.navbar-light .navbar-nav a.active, .navigation-clean-button.navbar-light .navbar-nav a.active:focus, .navigation-clean-button.navbar-light .navbar-nav a.active:hover {
  color: #8f8f8f;
  box-shadow: none;
  background: none;
  pointer-events: none;
}

.navigation-clean-button.navbar .navbar-nav .nav-link {
  padding-left: 18px;
  padding-right: 18px;
}

.navigation-clean-button.navbar-light .navbar-nav .nav-link {
  color: #465765;
}

.navigation-clean-button.navbar-light .navbar-nav .nav-link:focus, .navigation-clean-button.navbar-light .navbar-nav .nav-link:hover {
  color: #37434d !important;
  background-color: transparent;
}

.navigation-clean-button .navbar-nav > li > .dropdown-menu {
  margin-top: -5px;
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
  background-color: #fff;
  border-radius: 2px;
}

.navigation-clean-button .dropdown-menu .dropdown-item:focus, .navigation-clean-button .dropdown-menu .dropdown-item {
  line-height: 2;
  font-size: 14px;
  color: #37434d;
}

.navigation-clean-button .dropdown-menu .dropdown-item:focus, .navigation-clean-button .dropdown-menu .dropdown-item:hover {
  background: #eee;
  color: inherit;
}

@media (max-width:767px) {
  .navigation-clean-button .navbar-nav .show .dropdown-menu {
    box-shadow: none;
  }
}

@media (max-width:767px) {
  .navigation-clean-button .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #37434d;
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 1;
  }
}

.navigation-clean-button.navbar-dark {
  background-color: #1f2021;
  color: #fff;
}

.navigation-clean-button.navbar-dark .navbar-nav a.active, .navigation-clean-button.navbar-dark .navbar-nav a.active:focus, .navigation-clean-button.navbar-dark .navbar-nav a.active:hover {
  color: #8f8f8f;
  box-shadow: none;
  background: none;
  pointer-events: none;
}

.navigation-clean-button.navbar-dark .navbar-nav .nav-link {
  color: #dfe8ee;
}

.navigation-clean-button.navbar-dark .navbar-nav .nav-link:focus, .navigation-clean-button.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff !important;
  background-color: transparent;
}

.navigation-clean-button.navbar-dark .navbar-nav > li > .dropdown-menu {
  background-color: #1f2021;
}

.navigation-clean-button.navbar-dark .dropdown-menu .dropdown-item:focus, .navigation-clean-button.navbar-dark .dropdown-menu .dropdown-item {
  color: #f2f5f8;
}

.navigation-clean-button.navbar-dark .dropdown-menu .dropdown-item:focus, .navigation-clean-button.navbar-dark .dropdown-menu .dropdown-item:hover {
  background: #363739;
}

@media (max-width:767px) {
  .navigation-clean-button.navbar-dark .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #fff;
  }
}

.navigation-clean-button .actions .login {
  margin-right: 1rem;
  text-decoration: none;
  color: #465765;
}

.navigation-clean-button.navbar-dark .actions .login {
  color: #dfe8ee;
}

.navigation-clean-button.navbar-dark .actions .login:hover {
  color: #fff;
}

.navigation-clean-button .navbar-text .action-button, .navigation-clean-button .navbar-text .action-button:active, .navigation-clean-button .navbar-text .action-button:hover {
  background: #56c6c6;
  border-radius: 20px;
  font-size: inherit;
  color: #fff;
  box-shadow: none;
  border: none;
  text-shadow: none;
  padding: .5rem 1rem;
  transition: background-color 0.25s;
  font-size: inherit;
}

.navigation-clean-button .navbar-text .action-button:hover {
  background: #66d7d7;
}

.navigation-clean-button.navbar-dark .action-button, .navigation-clean-button.navbar-dark .action-button:active {
  background: #208f8f;
}

.navigation-clean-button.navbar-dark .action-button:hover {
  background: #269d9d;
}

nav_cen {
}

#navcol-1 {
  /*justify-content: center;*/
}

#nav_acco {
  margin-left: 1px;
  margin-right: 17px;
  margin-top: 3px;
}

.navbar-light .navbar-text {
}

#nav_logo {
  border-style: none;
  font-size: 20px;
  font-family: 'Montserrat Alternates', sans-serif;
}

#nav_bt {
  background: rgb(241,83,83); 
}

.hv_user{
  font-size: 27px;
  color:rgb(196, 36, 36)
}

.nv_flg{
  font-size: 10px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.fui84844{   
  border:1px solid rgb(241, 218, 218);
  font-family: Montserrat, sans-serif;
  
  padding: 0;
  height: 40px;
  text-align: right;

}

.us_jdu3883{
  color: black;
  font-weight: bolder;

}

.fff3456tf{ 
  padding: 0;

}

.ux_popo009{ 
  background-color: white;
}

.ux_jjhe8839{
  /* background-color: rgb(102, 17, 119) !important; */
  /* color: white !important; */
  /* border-radius: 20px;
   */
   font-weight: 200;
}

.uxid8883{
  font-size: 13px; 
}

@media only screen and (max-width:1200px) {
  .uxid8883{
    font-size: 13px; 
    height:50px !important;

  }
} 
.uxid88832{
  font-size: 15px;
}

.ux_nviriru{ 
  padding-left: 50px; 
}

.ux_jjhe8839b{
  background-color: rgb(195, 124, 209) !important;
  color: white !important;
  border-radius: 20px;
}

.hv_ie938{
   color: rgb(102, 17, 119) !important;
}

.ux_mt20s{
  margin-bottom: 20px;
}

.ux_djdj{
  height: 38px !important;
}

.ukbtn{
  background-color: rgb(71, 71, 71) !important;
  color:white;
  border-radius: 10px;
  font-weight: 500;
}

.ukbtn2{ 
  border-radius: 10px; 
  font-size: 25px;
  margin-left: 5px;
  cursor: pointer;
  color:rgba(0, 0, 255, 0.555)
}
.ukbtn2a{ 
  border-radius: 10px; 
  font-size: 25px;
  margin-left: 5px;
  cursor: pointer;
  color:rgba(255, 0, 0, 0.733)
}

.NE_btn_signup{
  border: none;
  background-color: transparent;
  border: 1px solid purple;
  /* background: linear-gradient(to right,rgb(63, 46, 46), rgb(104, 104, 104) ); */
  color: black !important;
  /* color: rgb(255, 255, 255) !important;  */
  padding: 5px 20px;
  border-radius: 9px;
  position: relative;
  top: 4px;
  font-weight: 700;
  box-shadow: 1px 1px 1px   rgb(51, 11, 27);
  text-decoration: none;
}
.NE_btn_signup:hover{
  text-decoration: none;
}

.NE_icon_outline{
margin-right: 5px;
position: relative;
top: -1px;
}

.containser{
  margin-top: 20px;
}

.fui84844s{
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
}

/* NEW CSS */

.main-container{
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  margin-bottom: 160px;
  position: fixed;
  top: 0;
  width: 100%;
 background: #f3f4f6 ;
  z-index: 20000;

}


.new-logo{
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.left-logo-section{
 
  display: flex;
  align-items: center;
  font-size: 40px;

}
.right-logo-section{
  display: flex;
  align-items: center;
  grid-gap: 40px;
  position: relative;

}
.profile-dropdown{
  position: absolute; 
  border: 1px solid rgb(233, 229, 229);
  top: 40px;
  right: -84px;
  width: 340px;
  /* padding: 16px; */
  background-color: #f9f9f9;
  box-shadow: -2px 5px 10px 1px rgb(0 0 0 / 18%);
  z-index: 1003;  
 border-top-left-radius: 10px;
 border-bottom-left-radius: 10px;
}

.profile-dropdown::after{
  display: block;
content: "";
position:absolute;
top: -18px;
margin-top: -3px;
width: 0;
left: 50%;
height: 0;
border-top: 10px solid transparent;
border-bottom: 12px solid #deebfa;
border-right: 12px solid transparent;
border-left: 12px solid transparent;
 }

.profile-name{
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  font-family:  Montserrat, sans-serif;
  font-size: 15px;
  position: relative;
  top: 1px;
}

.logo-logo{
  margin-right: 8px;
}

.logo-text{
  font-family:  Montserrat, sans-serif;
  font-size: 17px;
  font-weight: 700;
}

.new-menu{
  
  width: 100%;
  font-family:  Montserrat, sans-serif;
  font-size: 13px;
  margin-top: 46px;
  font-weight: 500;
  display: flex;
  /* justify-content: center; */
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  /* grid-gap: 4px; */
}

.menu-item:hover{
  color: rgb(29, 140, 245)
}

.dots-menu{
  position: relative;
  cursor: pointer;
  background-color: #f9f9f9;
  padding: 10px 8px;
  border-radius: 2px;
  border: 1px solid rgb(233, 229, 229);
}


.popout-menu{
  position: absolute;
  z-index: 20;
  border: 1px solid rgb(233, 229, 229);
  top: 40px;
  right: 0;
  width: 240px;
  /* padding: 16px; */
  background-color: #f9f9f9;
}

.dot-item{
  margin-top: 16px;
  margin-bottom: 16px;
cursor: pointer;
padding: 4px 20px;
}

.dot-item:hover{
  background-color: rgb(29, 140, 245);
  color: #fff;
}

.menu-item{
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px 28px;
  border-radius: 2px;
  border: 1px solid rgb(233, 229, 229);
}

 

.menu_icon2{
  margin-right: 5px;
  font-size: 16px;
}


.top-settings{
  position: relative;
  cursor: pointer;
  font-size: 20px; 
}

.settings-modal{
  position: absolute;
  top: 30px;
  right: -24px;
  background: #fff;
  box-shadow: -2px 5px 10px 1px rgb(0 0 0 / 18%);
  z-index: 1003; 
  width: 300px;
  border-radius: 10px;
 
}

.setting-container {
  border-radius: 10px;
  background: #fff;
 
}

.setting-section1{
  display: flex;
  align-items: center;
  justify-content: space-between; 
  
  background-color: #f3f8fe;
  padding: 24px;
  border-bottom: 1px solid #eee;
 

}

.setting-text{
  font-family:  Montserrat, sans-serif;
  font-size: 16px; 
  font-weight: 500;
  display: flex;
  align-items: center; 
}

.setting-times{
  text-align: right; 
  font-size: 18px;
  opacity: 0.8; 
}

.setting-section2{
 margin-top: 16px;
 font-family:  Montserrat, sans-serif;
  font-size: 13px; 
  margin-bottom: 24px;
  background: #fff;
}

.setting-item{
  display: flex;
  grid-gap: 24px;
  align-items: center;
  padding: 10px 24px; 
  cursor: pointer;
}
.setting-item:hover{
  background-color: #268ddc;
  color: #fff;
}

.settings-icon2{
  display: flex;
  align-items: center;
  font-size: 16px; 
  opacity: 0.5;
}

.profile-top{
  padding: 10px 20px;
  background-color: #f3f8fe!important;
  border-bottom: 1px solid #EDEDED;
  text-align: center;
}

.profile-close{
  text-align: right;
  margin-top: 10px;
  font-size: 18px;
  opacity: 0.8;
}

.profile-avatar{ 
 position: relative;
 left: 100px;
  background: #fff;
  width: 100px;
   
  padding: 10px 10px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-img{
  width: 70px;
}

.profile-name2{
   margin-top: 16px;
  font-family:  Montserrat, sans-serif;
  font-size: 15px; 
}
.profile-snum{
  margin-top: 8px;
  font-family:  Montserrat, sans-serif;
  font-size: 13px; 
}

.profile-email{
  margin-top: 8px;
  font-family:  Montserrat, sans-serif;
  font-size: 12px; 
}

.profile-signout{
  margin-top: 8px;
  font-family:  Montserrat, sans-serif;
  font-size: 15px;
  color: red;
  margin-bottom: 10px;
  cursor: pointer;
}

.profile-bottom{ 
  font-family:  Montserrat, sans-serif;

}

.profile-switch{
  opacity: 0.8;
  padding: 24px;
}

.profile-store{
  font-size: 14px;
  padding: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-store:hover{
  background-color: #268ddc;
  color: #fff;
}

.profile-check{ 
  color: rgb(56, 202, 56);
  font-size: 18px;
  position: relative;
  top: -2px;
}