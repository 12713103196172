 .cp_weuri{
     margin-bottom: 10px;
     font-size: 16px; 
     font-weight: 800; 
 }

 .ch_eywi{
     margin-top: 50px;
     font-family:'Nunito', sans-serif;
     font-weight: 800;
     font-size: 20px;
 }

 .ch_we92{
 margin-top: 20px;
    width: 50%;
 }

 .cp_jdjh883{
     text-align: center;
     margin-left: 90px;
 }

 .cp_iwr993{
     margin-top: 30px;
 }

 .change_pwd_line{
     display: flex;
     align-items: center;
     justify-content: space-between;
      width: 900px !important;
     
 }
 .chpwd_label{
     flex: 30%;
 }
 .chpwd_input{
     flex: 70%;
     border: 1px solid lightgrey;
 }

 .main_container{
    font-family: Montserrat, sans-serif;
    margin-left: 23px;
    font-weight: 800;
    
 }

 .cp_weuri2{
     text-align: center;
     margin-bottom: 25px;
 }

 .chpwd_submit{
     padding: 5px 25px;
     border: 1px solid lightgrey;
 }