.weeklyr_btn{
    margin-right: 20px;
}

.weeklyr_plus{
    font-size: 24px;
    color: rgb(33, 53, 33);
    cursor: pointer;
}

.weeklyr_minus{
    font-size: 24px;
    color: rgb(167, 17, 17);
    cursor: pointer;
}

.weeklyr_input{
    width: 100%;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.weeklyr_input:focus{
    border: 1px solid rgb(230, 165, 45); ;
    outline: none !important; 
}

.weekly_btn_submit{
    text-align: center;
    margin-top: 10px;
   
}
.weekly_btn_submit button {
    padding: 5px 20px;
    border-radius: 10px;
    border: 1px solid rebeccapurple;
    background-color: transparent;
    font-weight: bold;
}

