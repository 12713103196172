.tr_dji21 {
    color: var(--success);
  }
  
  .tr_linka {
    /* color: rgb(158, 22, 216) !important; */
    /* color: rgb(240, 222, 248) !important; */
    color:rgb(240, 231, 231) !important; 
    /* font-family: Andada, serif; */
    font-family: 'Open Sans', sans-serif;
    font-size: 15px; 
    margin-right: 20px;
    
    
  }

  .ux_admpan{
    background-color: rgb(75, 3, 90) ;
    /* opacity: 80%; */
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 15px; 
    text-align: center;
  }

  .ux_barcol{
    background-color: rgb(245, 245, 245);
    /* opacity: 50%; */
    border-radius: 20px;
    color:white;
    text-align: center;
    
  }

  .ux_bg{
    text-decoration: underline;
    border-radius: 20px;
     
   
    
  }

.bbactive{
   /* opacity:"100%"; */
   cursor:default;
   color: black !important;
   background-color: rgb(247, 246, 188) ;
   border-radius: 10px;
}
.bbnull{
  /* opacity: "50%"; */
  color: black !important;
}

.ux_heading9284{
  color:rgb(102, 101, 18) !important 
}

.ux_btnieoi2084{
  background-color: rgb(102, 17, 119) !important ; 
  color: white !important; 
  border-radius: 9px;
  margin-top: 15px;
}
.ux_btnieoi2084b{
  background-color: rgba(102, 17, 119, 0.801) !important ; 
  color: rgb(255, 255, 255) !important; 
  border-radius: 9px;
  margin-top: 15px;
}
  
.ux_iidu8e{
  margin-top: 20px !important
}

.ux_admpan{
  background-color: rgb(102, 17, 119) !important ; 
}
  
.ux_jsjsj222{
  background-color: black !important;
}

.jim_uk{
  border: 1px solid rgb(233, 230, 230);
  padding-left: 20px;
  margin-bottom: 20px;
}

