.wl_ww{
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: grey;
    /* position:absolute; */
    /* display: flex; */
    margin-top: 20px; 
height: 40px;
padding-top: 5px;  
width: 100%; 
}

.wl_sp{
    font-weight: 700;
    margin-left: 5px; 
}

.wl_fiwd{    
    text-align: right; 
    margin-left: auto;
    margin-right: auto; 
    width: 103%;
    margin-top: 10px;
}

.ux_jdkjwu399{
    justify-content: right;
}
 

.ux_8ejd8{
    color: rgb(0, 0, 0) !important;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 10px;
    margin-right: 50px !important;
}

 
.wl_wwss{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

 
.wl_wwss2{
    margin-top: 40px; 
    margin-bottom: 20px;
}

.wc_jdjh{
    background-color: transparent;
    font-weight: 700;
}