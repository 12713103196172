

#row3 {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.div_group {
  margin-right: auto;
  margin-left: auto;
  width: 97%;
  margin-top: 11px;
  padding-top: 17px;
  padding-bottom: 12px; 
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  filter: sepia(0%);
  border-width: 0px;  
  border: 1px solid rgb(233, 233, 233) ;
}

#divt {
  text-align: right;
  margin-left: 0;
  padding-left: 0px;
  margin-right: 54px;
}
 

.btmove {
  background: #ffeeee;
  color: rgb(0,0,0);
  border-radius: 0;
  border-width: 2px;
  border-color: #b05151;
}

 



.div_flex {
  height: 77px;
  width: 239px;
  margin-top: 2px;
  margin-right: 10px;
  border: 1px solid rgba(33,37,41,0.07) ;
  margin-bottom: 5px;
}

.form-control.input_size {
  width: 51px;
  margin-left: 21px;
}



.lall {
  width: 100px;
  font-family: Montserrat, sans-serif;
}

.report_bac {
  background: #f7f7f7;
  border-radius: 48px;
  border-style: solid;
  border-color: rgb(238,238,238);
  border-right-style: solid;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 3px;
  padding-left: 8px;
  text-align: center;
  height: 362px;
}

.col.col_mid2 {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 10px;
}

.suar {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.arv5 {
  /*color: red;*/
  font-size: 18px;
  text-align: center;
  margin-left: 30px;
  margin-top: 10px;
}

.fitcar {
  /*text-align: center;*/
  justify-content: center;
  /*justify-items: center;*/
  /*background-color: red;*/
}

.revie {
  border-style: solid;
  border-color: rgba(95, 91, 91, 0.15);
  margin-top: 59px;
  margin-bottom: 0;
  padding-bottom: 27px;
  border-radius: 25px;
}

p {
  font-family: Montserrat, sans-serif;
}

.col.flex-wrap.ffgg {
  overflow: scroll;
}

.col.ffgg {
  overflow: scroll;
  height: 150px;
  font-size: 10px;
}

.form-control.tty {
  width: 71px;
  height: 27px;
  margin-top: 5px;
}

.gh_flex {
  display: flex;
  /*vertical-align: middle;*/
}

.width_p {
  width: 109px;
  height: 45px;
  font-size: 12px;
  padding-top: 4px; 
}

.div_btn {
  text-align: center;
  padding-right: 0px;
  margin-top: 9px;
}

.btn_y {
  margin-right: 104px;
  margin-top: -0;
}

.breadcrumb.bbd {
  background: transparent;
  margin-top: 14px;
}

 

.reportfor{
  margin-left: 20px;
  font-size: 15px; 
  margin-top: 10px;
  margin-bottom: 15px;
}

.body_body{
  margin-top: 190px;
 
}

.ux_jdj4psin{
  text-align: center;
  font-family: Montserrat, sans-serif;
  margin-top: 20px;
}

.ux_heeedd{
  background-color:rgb(102, 17, 119) ;
  color: rgb(255, 255, 255) !important;
  font-size: 14px; 
    text-align: center;
    padding:5px;
    font-weight: 500;
}

.ux_textall{
  font-size: 14px !important;
}

.us_spanur83{
  margin-left: 50px;
  font-size: 12px !important;
}

.dsd_uduz{
  margin-top: -9px !important;
}

.fux_jde33{
  margin-left: 12px;
}

.ux_mt20{
  margin-top: 20px !important;
}

.uk_btn{
  /* background-color: rgb(105, 2, 105); */
  border-radius: 10px !important;
  color: black !important;
}

body{
  /* background: linear-gradient(#f3f2e4, #f6f7f8) fixed;  */
  background-image: url("images/backy.jpeg");
  /* background-color: rgb(241, 241, 241); */
  /* background-color: rgb(246, 245, 247); */
  background-repeat: no-repeat;
}