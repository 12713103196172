.tr_eowi992{
    padding-top: 5px;
    padding-bottom: 5px; 
}

.tr_breow9{
    height: 5px;
    /* color: #d4b5c5; */
}

.ux_upojd8{
    background-color: rgb(102, 17, 119);
    height: 40px;
}

.ux_updi8{
    background-color: rgb(250, 223, 255);
}

.ux_upfjr99{
    color: #eee2e8
}