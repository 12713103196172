 

.sd_one {
  margin-bottom: -20px;
}

.sd_two {
  margin-bottom: -1px;
  padding-bottom: -14px;
}

.sd_three {
  margin-bottom: 0;
}

.sd_four {
  margin-top: -7px;
}

.sd_five {
  margin-right: 15px;
}

.sales-delete{
  text-align: center;
  margin-top: 40px;
}