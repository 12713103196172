.login-clean {
    /* background: #f1f7fc; */
    padding: 140px 0;
  }
  
  .login-clean form {
    max-width: 420px;
    width: 90%;
    margin: 0 auto;
    /* background-color: transparent; */
    background-color: transparent;
    border: 1px solid rgb(206, 203, 203);
    padding: 40px;
    border-radius: 34px;
    color: #505e6c;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
  }
  
  .login-clean .illustration {
    text-align: center;
    padding: 0 0 5px;
    font-size: 100px;
    color: rgb(102, 17, 119);
  }
  
  .login-clean form .form-control {
    /* background: #f7f9fc; */
    border: none;
    border-bottom: 1px solid #dfe7f1;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    text-indent: 8px;
    height: 42px;
  }
  .form-groupxx{
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
  }

  .lg_btn_btn{
    margin-top: 40px;
    padding: 5px 20px;
    /* background-color: purple; */
    /* background-color: transparent; */
    border: 2px solid lightgrey;
    border-radius: 7px;
    color: black;
    font-weight: 600;
    /* box-shadow: 1px 1px 5px 1px black; */
  }

  .form-controlx{
    width: 80%;
    padding: 10px; 
    background: transparent;
    border: 2px solid lightgrey;
    border-radius: 2px;
    font-weight: bold;
  }
   
  
  .login-clean form .btn-primary:hover, .login-clean form .btn-primary:active {
    background: #eb3b60;
  }
  
  .login-clean form .btn-primary:active {
    transform: translateY(1px);
  }
  
  .login-clean form .forgot {
    display: block;
    text-align: center;
    font-size: 14px; 
    opacity: 0.9;
    text-decoration: none;
    margin-top: 80px;
    color: black;
    font-weight: 600;
  }
  
  .login-clean form .forgot:hover, .login-clean form .forgot:active {
    opacity: 1;
    text-decoration: none;
  }

  .lg_inv{
    text-align: center; 
    font-size: 16px;
  }
.lg_color_logo{
  color: rgb(68, 2, 68);
}
   