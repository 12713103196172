#example {
    margin-bottom: 0;
  }
  
  #example {
    margin-bottom: 0;
  }
  
  .btn {
    margin: 0px 10px;
    margin-bottom: 20px;
  }

  .us_cc{
    margin-top: 10px;
  }