.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
    }
    100% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
    }
    100% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    } 
  }

  .pt_wioew{
    background-color: rgb(241, 241, 241);
  }

  .pt_woe{
    font-weight: 600;
  }
  
  .pc_dwe{
    background-color: rgb(255, 255, 255);
  }
  .disburse{
    background-color: rgb(241, 241, 241);
  }
  .reimburse{
    background-color: rgb(255, 255, 255);
  }

  .pt_uruuw{
    background-color: rgb(64, 41, 85);
  }
  .pt_btntswe{
    color: white;
  }
  .pt_eow{
     color: rgb(64, 41, 85);
  }
  .pt_ei4{
    color:   rgb(64, 41, 85);
  }