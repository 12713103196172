.si_cn{
    text-align: center;
    display: flex; 
    justify-content: center;
    padding: 10px;
    font-size: 12px;
}

.si_in{
    width: 30%;
    margin-left: 20px;
    margin-top: -5px;
}

.sai_main{
    margin-top: 1.5rem;

}
.si_main{
    text-align: center;
    font-size: 2rem;
    font-family: Montserrat, sans-serif;
    margin-top: 15px;
    margin-bottom: 22px;
    font-variant: small-caps;
}
.sialert{
    text-align: center;
    color: green;
    /* margin-bottom: 10px; */
    /* background-color: rgb(255, 240, 240);  */
    font-family: Montserrat, sans-serif;
    padding: 3px;
    font-weight: 600;
}

.sialert2{
    text-align: center;
    font-weight: 400;
    font-size: 13px;

}

.si_rowre{
    text-align: center; 
    color: red;
    margin-bottom: 15px;
}

.si_weoi{
    margin-bottom: 20px;
}

.disabled{
    pointer-events: none;

  /* for "disabled" effect */
  opacity: 0.5;
  /* background: #CCC; */
  
}

.si_rwiuev{
    background-color: rgb(255, 236, 236);
}


.si_rwiuev2{
    background-color: rgb(255, 236, 236);
}

.ux_uru777{
    font-size: 16px !important;
    text-align: left ;
    
}

.ux_dhyru23{
     color: white !important;
     background-color:rgba(102, 17, 119, 0.671) !important;
}

.si_irw992{
    margin-left: 10px;
    padding: 2px;
}

.si_duw99{
    display: flex;
    font-size: 13px !important;
    padding: 0 !important;
    margin-left: 0px;
    margin-top: 30px !important;
    justify-content: center;
    margin-bottom: 15px;
    /* background-color: red; */
}

.si_ieu88{
    font-size: 13px !important;
    padding: 3px !important; 
    height: 30px;
    width: 70px;
    margin-left: 10px;
    /* background-color: red; */
}

.si_sdwee11{
    display: flex;
    margin-left: 0px;
    margin-right: 4rem
}

.si_djhw722{
    margin-left: 35px;
    margin-right: 10px;
}

.spinnerxd{
    margin-top: 40px;
}

/* New CSS */
 
.sales-form{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    font-size: 15px;
    grid-gap: 20px;
    background: rgb(244, 248, 250);
}

.sales-first-col{
    flex: 50%;
    border-right: 1px solid rgb(255, 246, 246);
    padding-right: 20px;
}

.sales-second-col{
    flex: 50%; 
}

.sales-input-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}

.sales-text{
    flex: 30%;
}

.sales-input{  
    margin-left: 80px;
   flex: 70%;
    display: block;
   
    width: 100%; 
    padding: 6px 12px;
    font-size: 1rem;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}

.sales-input:focus{
    outline: none !important;
    border:1px solid #719ECE;
    box-shadow: 0 0 5px #719ECE;
}

.sales-person-dropdown{
    flex: 46%;
    display: block;
    width: 100%; 
    padding: 6px 12px;
    font-size: 1rem;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
 

}

.sales-person-option{
    padding: 6px 12px;
    margin-top: 10px;
  
}

.sales-checkbox-input{
    margin-right: 20px;
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    padding: 10px;
    
}

.sales-checkbox{
    margin-top: 20px;
}

.sales-cta{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.sales-reset{
    width: 150px;
    background: rgb(247, 247, 247);
    border: 1px solid rgb(224, 224, 224);
    padding: 7px 14px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
}

.sales-reset:hover{
    border: 1px solid rgb(181, 236, 250);
    background: rgb(255, 254, 254);
    
}

.sales-report-form{
    font-weight: 700;
    font-size: 15px;
    border:none;
    background-color: transparent;
    font-family: Montserrat, sans-serif; 
}

.sales-report-form:focus{
    outline: none;
}
.sales-alert{
    text-align: center;
}

.sales-hr{
    /* opacity: 0.5; */
    padding-bottom:0 ;
    margin-top: 0;
    margin-bottom: 0;
    
}