.vs_pos{
    margin-top: 10px;
    margin-bottom: 15px;
}

.vs_pos1{ 
    max-height: 170px;
    overflow: auto;
}

.ukbtnx{
    font-size: 18px !important;

}

.select_year_vs{
    position: relative;
    top: 12px;
    height: 35px;
    padding: 3px 10px;
    border-radius: 5px;
}