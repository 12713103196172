 

.pc_bal {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  text-align: right;
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 5px;
  padding-top: 3px;
  padding-bottom: 4px;
}

.pc_span {
  color: rgb(0,0,0);
  padding-right: 9px;
  padding-left: 16px;
}

.pc_bracket2 {
  height: 120px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fb4242;
  border-radius: 36px;
  box-shadow: inset -4px 5px 2px rgb(252,222,222);
  opacity: 0.79;
  margin-left: auto;
  margin-right: auto;
  padding-left: 23px;
}

.pc_bracket {
  height: 153px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #287eff;
  border-radius: 36px;
  box-shadow: inset -4px 5px 2px rgb(175,210,245);
  opacity: 0.79;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4px;
  padding-left: 23px;
}

.col.pc_col1 {
  width: 295px;
}

.pc_header {
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  line-height: 21px;
  font-size: 26px;
  margin-top: 20px;
  text-align: center;
  letter-spacing: 2px;
  border-style: none;
  opacity: 1;
}

.pc_inflow {
  margin-top: 3px;
  margin-bottom: 5px;
  text-align: center;
  color: rgb(255,255,255);
  font-size: 15px;
  font-family: Montserrat, sans-serif;
}
 

.pc_amount2 {
  margin-left: 2px;
}

@media (min-width: 360px) {
  .pc_input {
    margin-top: -7px;
    width: 80px;
    height: 20px;
    margin-right: 0px;
    margin-left: 6px;
    background: rgba(255,255,255,0.39);
    border: 1px black solid;
  }
}

.pc_input {
  margin-top: -7px;
  width: 80px;
  height: 30px;
  margin-right: 0px;
  margin-left: 6px;
  background: rgba(255,255,255,0.39);
  border: 1px black solid;
}

.pc_input2 {
  position: relative;
  margin-top: -7px;
  width: 60%;
  height: 30px;
  margin-right: 0px;
  margin-left: 15px;
  background: rgba(255,255,255,0.39);
  border: 1px black solid;
}
 

.d-xl-flex.align-items-xl-center.pc_pad {
  padding-left: 15px;
}
 
.pc_btn {
  margin-left: 35px;
  width: 9rem;
  margin-right: 14px;
  background: #0652a6;
  border-radius: 23px;
  box-shadow: 1px 1px 6px 0px;
 
}

.pc_btn2 {
  margin-left: 35px;
  width: 9rem;
  margin-right: 14px;
  background: #910101;
  border-radius: 23px;
  box-shadow: 1px 1px 6px 0px;
}
 

.pc_bb {
  background: rgb(247,247,247);
  border-radius: 0px;
  font-size: 15px;
  margin-left: 0;
  margin-right: 0;
  padding-right: 2px;
  padding-left: 0; 
}

.pc_sell {
    background-color: rgb(235, 227, 227);
    cursor: pointer;
    
  border: 1px solid rgb(204,204,204) ;
    
}

.pc_ingo{
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
    font-family: Montserrat, sans-serif;
    font-size: 11px;
    color: rgb(139, 139, 139);
    line-height: 18px;
}

.pc_nsell{
    background-color: white;  
    cursor: default;
}
.pc_click1{
    cursor: pointer;
}
.pc_hhu{
    /* margin-top: 20px; */
    font-size: 12px;
}

.petty_submit{
  border: 1px solid lightgrey;
  padding: 3px 10px;
}
.petty_close{
  border: 1px solid lightgrey;
  padding: 3px 10px;
  margin-left: 10px;
}

.pc_divc {
  width: 100%;
}

.pc_diva {
  width: 100%;
  background: #ffffff;
  margin-right: 0;
  margin-left: 0;
  border: 1px solid rgb(204,204,204) ;
  padding-bottom: 20px;
}

.col-12.col-sm-12.col-md-12.col-lg-12.col-xl-12.gth {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.row.ppc_r1 {
  margin-right: 0px;
  margin-left: 0px;
}

.pc_cent {
  padding-top: 10px;
  text-align: center;
  font-family: Montserrat, sans-serif;
  line-height: 20px;
  font-weight: normal;
  color: rgb(30,33,35);
}

.col.pc_nactive {
}

.col.pc_inp {
  margin-right: 0px;
  margin-top: 56px;
  margin-bottom: 10px;
}

.pv_div_cent {
  margin-left: 20px;
  margin-top: 20px;
}

.row.pc_rwd {
  margin-right: 20px;
  margin-left: 20px;
}

.pc_col8 { 
}

.col.pc_col9 {
  text-align: left;
  padding-left: 0;
  margin-left: 16px;
}

.pc_text {
  margin-top: 6px;
  width: 100px;
  padding-left: 1px;
}

.pc_text2 {
  margin-top: 8px;
  width: 15rem;
}

.pc_tt {
  width: 100%;
}

.row.pc_rww3 {
  margin-right: 0px;
  margin-left: 0px;
}

.col.pc_btjs {
  margin-top: 30px;
}

.pc_bb {
  width: 80%;
}

.pc_jdj {
  width: 80%;
  font-size: 16px;
  background: #d54b4b;
  border-radius: 33px;
}

.pc_ccc{
    text-align: center;
}

 

.form-check-label:before{
    color:red
  }
  .form-check-input:checked~.form-check-label::before{
    background-color:black;
  }

  .form-check-input:checked{
      background: red!important;
  }

  .form-check-label{
      color:red
  }


  input[type=checkbox]:checked + label {
    color: black;
  }

  input[type=checkbox]:checked{
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 0, 247, 0.25); 
    background-color: #face00;  border-color: #face00;
  }

  .pc_ssp{
      font-size: 18px;
      color: rgb(23, 162, 255);
  }
 
  .hidden {
      visibility: hidden;

  }

  .pt_show {
      visibility: visible;
      width: 40%;
      background-color: rgb(255, 228, 228);
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-top: 10px;
      border-radius: 20px;

  }

  .pc_ttbs{
      height: 500px;
      overflow: scroll;
  }

  .pc_yyy{
      height: 25px;
      width: 55px;
      text-align: center; 
      padding-top: 2px;
      padding-bottom: 5px;
      font-size: 10px;
      border-radius: 20px;
      margin-top: 5px;
  }

  .pc_edit{
      margin-top: 10px;
      margin-bottom: 10px;
      background-color: #edfdeb;
      padding: 5px;
  }

  .pc_edd{
      text-align: center;
      font-family: Montserrat, sans-serif;
      font-weight: bold;
  }

  .pc_form{
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 5px;
      margin-bottom: 5px;
  }

  .pc_ed_div{
      margin-top: 10px;
      display: flex; 
      font-family: Montserrat, sans-serif;
      color: black;
    
  }

  .pc_ed_lb{
      margin-right: 10px;
      margin-top: 5px;
      width: 6rem;
  }

  .pc_ed_btn{
      text-align: center;
      margin-top: 10px;
  }

  .pc_ed_btnr{
      border-radius: 20px;
      margin-right: 15px;
      margin-left: 15px;
  }

  .pc_ed_sel{
      margin-right: 10px;
      margin-left: 10px;
      padding-right: 10px; 
      color: black;
  }
  .pc_ed_sel2{ 
   padding-right: 5px; 
   color: black;
  }

   

   

  .ptc{
    margin-top: -15px;
    top:0
  }

  tr {
    position: sticky;
    top: 0px;
    margin-bottom: 0px;
  }  

  .spinnerx{
    text-align: center;
    margin-left: auto;
    margin-right: auto;   
    margin-top: 15%
  }
  
  .spinnerx2{
  }

  .pc_yydd2{
     font-size: 10px;
  }

  .pc_tren{
    text-align: center;
  }

  .us_jue2{
   color: rgb(1, 8, 5)
  }


  @media only screen and (max-width: 1000px) {
    .xbs_idu{
      margin-right: 10px !important; 
    }
  }
  
  .sales_input_petty{
    width: 100%;
    padding: 3px 15px;
    border: 1px solid lightgrey;
    flex: 75%;
  }

  .petty_flex_label{
    flex: 25%;
  }
  .petty_btn{
    background-color: rgb(19, 85, 105) !important;
    color: white !important;
    border: 1px solid lightgrey !important;
    padding: 5px 35px !important;
    font-weight: 600;
    border-radius: 2px !important;
    font-size: 13px;
  }

  .petty_amount_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
     
  }

  .petty_amount_flex:first-child{
    margin-bottom: 10px;
  }

  .row_pchead {
    margin-top: 40px;
    font-size: 15px;
  }

  .col_pchead{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .pc1_cashavailable{
    margin-left: 8px;
  }