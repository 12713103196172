.pr_d88{ 
    margin: 5px;
    width:80%
}

.pr_jf88{
    font-size: 12px;
}

.pr_d88 a{
    color: white;

}

.pr_jd983{
    font-size: 20px;
    cursor: pointer;
}

.pr_iiee8{
    margin-top: 4px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;

 
    width:150px;
    /* background-color: red; */
}
.pr_dk201{
    font-weight: bold;
    flex: 50%;
}

.pr_dk202{
     
    flex: 50%
}
 
  
 